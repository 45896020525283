//import { createPinia } from 'pinia';
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import { errorLoggerPlugin } from './service/LoggerService';

import '@/assets/icons/css/icons.css';
import '@/assets/styles.scss';
import '@/assets/tailwind.css';

const MyPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '#edfaff',
            100: '#d6f3ff',
            200: '#b5ecff',
            300: '#83e2ff',
            400: '#48cfff',
            500: '#1eb1ff',
            600: '#0694ff',
            700: '#0075e9',
            800: '#0861c5',
            900: '#0d549b',
            950: '#0e335d'
        },
        colorScheme: {
            light: {
                primary: {
                    color: '#0075e9',
                    inverseColor: '#ffffff',
                    hoverColor: '#0075e9',
                    activeColor: '#0075e9'
                },
                highlight: {
                    background: '#0075E9',
                    focusBackground: '#0075E9',
                    color: '#ffffff',
                    focusColor: '#ffffff'
                },
                formField: {
                    hoverBorderColor: '{primary.color}',
                    floatLabelFocusColor: '{primary.color}'
                }
            }
        }
    }
    /*components: {
        floatlabel: {
            focusColor: '{primary-color}'
        },
        message: {
            textFontWeight: '100'
        }
    }*/
});



const app = createApp(App);

//const pinia = createPinia();
//pinia.use(piniaPluginPersistedstate);

app.use(router);
app.use(PrimeVue, {
    theme: {
        preset: MyPreset,
        options: {
            darkModeSelector: '.app-dark'
        }
    }
});
app.use(ToastService);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);
app.use(errorLoggerPlugin);
//app.use(pinia);

app.mount('#app');
