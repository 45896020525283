import axios from "axios";
//import { useStore } from '../stores/store';
import store from '../stores/store';

export const getQuotes = () => {
    //const store = useStore();
    let queryParams = '';
    // if user is being impersonated add the user id to the request query
    if (Object.keys(store.impersonator).length > 0) {
        queryParams = '?impersonate=' + store.user.Id;
    }
    axios({
        method: 'GET',
        url: import.meta.env.VITE_APP_API + '/quotes' + queryParams,
        headers: { Authorization: 'Bearer ' + store.token }
    })
        .then((response) => {
            store.quotes = response.data;
        })
        .catch((error) => {
            console.error(error);
        });
};