import axios from "axios";
//import { useStore } from '../stores/store';
import store from '../stores/store';

export const getBuyerCompanies = () => {
    //const store = useStore();
    let apiPath = '';
    if (store.user.scopes.includes('admin') || store.user.scopes.includes('super_admin')) {
        apiPath = import.meta.env.VITE_APP_API + "/companies/buyer";
    } else {
        apiPath = import.meta.env.VITE_APP_API + "/companies/buyer?parent_id=" + store.user.company.ContactId;
    }

    axios({
        method: 'GET',
        url: apiPath,
        headers: { Authorization: 'Bearer ' + store.token }
    })
        .then((response) => {
            store.buyerCompanies = response.data;
        })
        .catch((error) => {
            console.error(error);
        });
};