// src/errorLogger.js
import axios from 'axios';

const logErrorToBackend = (error, url, lineNumber, columnNumber, errorObj) => {
    const errorDetails = {
        message: error,
        url: url,
        line: lineNumber,
        column: columnNumber,
        stack: errorObj?.stack,
    };

    // Replace with your backend endpoint
    axios.post(import.meta.env.VITE_APP_API + '/ui-logger', errorDetails)
        .then(response => {
            //console.log('Error logged successfully:', response);
        })
        .catch(err => {
            //console.error('Error logging failed:', err);
        });
};

// Export the function and also attach it to the global window object
export { logErrorToBackend };

// Optionally, you can attach it to the global Vue instance
export const errorLoggerPlugin = {
    install(app) {
        app.config.globalProperties.$logError = logErrorToBackend;
    },
};
