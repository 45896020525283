import axios from "axios";
//import { useStore } from ../stores/store';
import store from '../stores/store';

export const getBuyerContacts = () => {
    //const store = useStore();
    // if user has admin scope show all buyer companies, else only those belonging to them
    let apiPath = '';
    if (store.user.scopes.includes('admin') || store.user.scopes.includes('super_admin')) {
        apiPath = import.meta.env.VITE_APP_API + "/contacts/buyer";
    } else {
        apiPath = import.meta.env.VITE_APP_API + "/contacts/buyer?seller_id=" + store.user.company.ContactId;
    }

    axios({
        method: "GET",
        url: apiPath,
        headers: { Authorization: 'Bearer ' + store.token },
    }).then((response) => {
        let tempContacts = response.data;
        tempContacts = response.data.map(obj => ({
            ...obj,
            emails: obj.emails.length > 0 ? obj.emails[0] : null,
            phones: obj.phones.length > 0 ? obj.phones[0] : null
        }));
        store.buyerContacts = tempContacts;
    })
    .catch((error) => {
        console.error(error);
    });
};