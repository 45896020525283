<script setup>
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import store from '../stores/store';

const router = useRouter();
const route = useRoute();
//const store = useStore();
//const { user, token, getUser, getPermissions, getToken } = storeToRefs(store);

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
    {
        items: [
            { label: 'Dashboard', icon: 'icon icon-24 icon-dashboard', to: '/', scopes: ['super_admin', 'admin', 'sales_manager', 'sales_person'] },
            { label: 'AF Sales Managers', icon: 'icon icon-24 icon-af-sales-managers', to: '/af-sales-managers', scopes: ['super_admin', 'admin'] },
            { label: 'Seller Companies', icon: 'icon icon-24 icon-seller-company', to: '/seller-companies', scopes: ['super_admin', 'admin'] },
            { label: 'Seller Contacts', icon: 'icon icon-24 icon-seller-contact', to: '/seller-contacts', scopes: ['super_admin', 'admin'] },
            /* { label: 'My Team', icon: 'icon icon-24 icon-team', to: '/my-team', scopes: ['sales_manager'] }, */
            { label: 'Buyer Companies', icon: 'icon icon-24 icon-buyer-company', to: '/buyer-companies', scopes: ['super_admin', 'admin', 'sales_manager', 'sales_person'] },
            { label: 'Buyer Contacts', icon: 'icon icon-24 icon-buyer-contact', to: '/buyer-contacts', scopes: ['super_admin', 'admin', 'sales_manager', 'sales_person'] },
            { label: 'Quotes', icon: 'icon icon-24 icon-quotes', to: '/quotes', scopes: ['super_admin', 'admin', 'sales_manager', 'sales_person'] },
            { label: 'Tiers', icon: 'icon icon-24 icon-tiers', to: '/tiers', scopes: ['super_admin', 'admin'] }
        ]
    }
]);

const cancelImpersonation = () => {
    let navTarget = store.user.company.Id;
    //store.resetImpersonation();
    store.user = store.impersonator;
    store.impersonator = {};
    //document.location.href="/"; // force reload, until the bug with router push is resoved
    router.push({
        path: '/seller-companies/'+navTarget,
    });
}
</script>

<template>
    <div class="flex flex-col justify-between" style="height:calc(100vh - 7rem)">
        <div>
            <ul class="layout-menu">
                <template v-for="(item, i) in model" :key="item">
                    <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
                    <li v-if="item.separator" class="menu-separator"></li>
                </template>
            </ul>
        </div>
        <div>
            <Button icon="pi pi-times" label="Cancel Impersonation" severity="info" text v-if="Object.keys(store.impersonator).length > 0" @click.prevent="cancelImpersonation" />
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
