import { reactive, watch } from 'vue';

const initialState = {
    user: {},
    dashboard: [],
    impersonator: {},
    virgin: {},
    permissions: {},
    token: null,
    dashboard: {},
    afManagers: [],
    sellerCompanies: [],
    sellerContacts: [],
    buyerCompanies: [],
    buyerContacts: [],
    quotes: [],
    tiers: [],
    searchTerm: '',
    quoteWizard: {
        show: false,
        created: false,
        activeStepIndex: 0,
        level: 'admin',
        steps: {
            standard: [
                { id: '01', name: 'Buyer Company', description: 'create new buyer company', href: '#', status: 'current' },
                { id: '02', name: 'Buyer Contact', description: 'create new buyer contact', href: '#', status: 'upcoming' },
                { id: '03', name: 'Create Quote', description: 'enter quote details', href: '#', status: 'upcoming' },
                { id: '04', name: 'Confirm and Send', description: 'download or send quote to buyer', href: '#', status: 'upcoming' },
            ],
            admin: [
                { id: '01', name: 'Seller', description: 'choose sales person', href: '#', status: 'current' },
                { id: '02', name: 'Buyer Company', description: 'create new buyer company', href: '#', status: 'upcoming' },
                { id: '03', name: 'Buyer Contact', description: 'create new buyer contact', href: '#', status: 'upcoming' },
                { id: '04', name: 'Create Quote', description: 'enter quote details', href: '#', status: 'upcoming' },
                { id: '05', name: 'Confirm and Send', description: 'download or send quote to buyer', href: '#', status: 'upcoming' },
            ]
        },
        sellerContactId: null,
        sellerCompanyId: null,
        salesPersonId: null,
        salesPersonName: null,
        salesPerson: null,
        salesPersonCompany: null,
        buyerCompany: {
            contactId: null,
            companyId: null,
            name: null
        },
        buyerContact: {
            contactId: null,
            name: null,
            email: null,
            phone: null,
        },
        quote: {
            id: null,
            equipment: null,
            quantity: null,
            total_value: 0
        }
    }
};

// Retrieve the existing state from localStorage, or use initialState
const storedState = JSON.parse(localStorage.getItem('amplifiStore')) || initialState;

// Create a reactive store
const store = reactive(storedState);

// Watch for changes in the entire store and update localStorage
watch(
    store,
    (newValue) => {
        localStorage.setItem('amplifiStore', JSON.stringify(newValue));
    },
    { deep: true }
);

export default store;