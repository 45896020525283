<template>
    <router-view />
    <QuoteWizard @close="resetQuoteWizard();" :show="store.quoteWizard.show" v-if="Object.keys(store.user).length > 0"/>
</template>

<style scoped></style>

<script>
import axios from 'axios';
//import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, ref, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import QuoteWizard from './components/quotes/QuoteWizard.vue';
//import { useStore } from './stores/store';
import store from './stores/store';
import { getQuotes } from '@/service/QuotesService';
import { getBuyerCompanies } from '@/service/BuyerCompaniesService';
import { getBuyerContacts } from '@/service/BuyerContactsService';

export default defineComponent({
    name: 'App',
    components: {
        QuoteWizard
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        //const { user, token, getUser, getPermissions, getToken } = storeToRefs(store);
        //const { setUser, setToken, getSearchTerm } = storeToRefs(store);
        const hostname = ref(window.location.hostname);

        const resetQuoteWizard = () => {
            store.quoteWizard.show = false;
            if(Object.keys(store.user).length > 0) {
                if(store.user.scopes.includes('admin') || store.user.scopes.includes('super_admin')) {
                    store.quoteWizard.level = 'admin';
                } else {
                    store.quoteWizard.level = 'standard';
                }
            } else {
                store.quoteWizard.level = 'admin';
            }
            store.quoteWizard.created = false;
            store.quoteWizard.activeStepIndex = 0;
            store.quoteWizard.steps.standard[0].status = 'current';
            store.quoteWizard.steps.standard[1].status = 'upcoming';
            store.quoteWizard.steps.standard[2].status = 'upcoming';
            store.quoteWizard.steps.standard[3].status = 'upcoming';

            store.quoteWizard.steps.admin[0].status = 'current';
            store.quoteWizard.steps.admin[1].status = 'upcoming';
            store.quoteWizard.steps.admin[2].status = 'upcoming';
            store.quoteWizard.steps.admin[3].status = 'upcoming';
            store.quoteWizard.steps.admin[4].status = 'upcoming';

            store.quoteWizard.sellerContactId = null;
            store.quoteWizard.sellerCompanyId = null;
            store.quoteWizard.salesPerson = null;
            store.quoteWizard.salesPersonId = null;
            store.quoteWizard.salesPersonName = null;
            store.quoteWizard.salesPersonCompany = null;
            
            store.quoteWizard.buyerCompany.contactId = null;
            store.quoteWizard.buyerCompany.companyId = null;
            store.quoteWizard.buyerCompany.name = null;

            store.quoteWizard.buyerContact.contactId = null;
            store.quoteWizard.buyerContact.name = null;
            store.quoteWizard.buyerContact.email = null;
            store.quoteWizard.buyerContact.phone = null;

            store.quoteWizard.quote.id = null;
            store.quoteWizard.quote.equipment = null;
            store.quoteWizard.quote.quantity = null;
            store.quoteWizard.quote.total_value = 0;
        }

        onMounted(() => {
            resetQuoteWizard();
            if(Object.keys(store.user).length > 0) {
                if(store.user.scopes.includes('admin') || store.user.scopes.includes('super_admin')) {
                    store.quoteWizard.level = 'admin';
                } else {
                    store.quoteWizard.level = 'standard';
                }
            }
        });

        const timer = () => {
            setInterval(() => {
                let currentPath = router.currentRoute.value;
                if (currentPath.path !== '/login' && currentPath.path !== '/password-set') {
                    whoAmI();
                }
            }, 60000);
        };

        const cancelImpersonation = () => {
            let navTarget = store.user.company.Id;
            //store.resetImpersonation();
            getBuyerCompanies();
            getBuyerContacts();
            getQuotes();
            document.location.href = '/seller-companies/'+navTarget; // force reload, until the bug with router push is resoved
            router.push({
              path: '/seller-companies/'+navTarget,
            });
        };

        const whoAmI = () => {
            axios({
                method: 'GET',
                url: import.meta.env.VITE_APP_API + '/whoami',
                headers: { Authorization: 'Bearer ' + store.token }
            })
                .then((response) => {
                    //
                })
                .catch(() => {
                    //currentActiveItem.value = '/'; //reset navigation
                    resetQuoteWizard(); //reset quote wizzard
                    router.push({
                        path: '/login'
                    });
                });
        };

        whoAmI();
        timer();

        return {
            whoAmI,
            onMounted,
            store,
            route,
            cancelImpersonation,
            resetQuoteWizard
        };
    }
});
</script>
